export const generateGoogleMapsLink = (customer) => {
  const { zip, street, streetNumber } = customer;
  const fullAddress = `${street} ${streetNumber}, ${zip}`;
  const encodedAddress = encodeURIComponent(fullAddress);
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
};

export const startTeamsCall = (phoneNumber) => {
  const teamsCallLink = `https://teams.microsoft.com/l/call/0/0?users=,4:${phoneNumber}`;
  window.location.href = teamsCallLink;
};
