<template>
  <div class="sales-table" v-if="projects">
    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="salesTable"
      :value="projects"
      dataKey="number"
      :lazy="true"
      :loading="isLoading"
      :totalRecords="getProjectCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30, 40, 50, 100, 200]"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      @cell-edit-complete="onCellEditComplete"
      :rowHover="true"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ cardTitle }}</h4>
          </div>
          <div class="table-header-right">
            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        v-if="isAdmin"
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number"
            :to="{
              name: 'SalesEditPage',
              params: { projectNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.lastname"
        :header="$t('lastname')"
        filterField="customerLastname"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.firstname"
        :header="$t('firstname')"
        filterField="customerFirstname"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.street"
        :header="$t('street')"
        filterField="customerStreet"
        sortable
        sortField="customer.street"
      >
        <template #body="slotProps">
          <a :href="generateGoogleMapsLink(slotProps.data.customer)" target="_blank">
            {{ slotProps.data.customer.street }}
          </a>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.zip"
        :header="$t('zip')"
        filterField="customerZip"
        sortable
        sortField="customer.zip"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.city" :header="$t('city')" filterField="customerCity" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.phone" :header="$t('phone')" filterField="customerPhone" sortable>
        <template #body="slotProps">
          <a @click.prevent="startTeamsCall(slotProps.data.customer.phone)" href="#">
            {{ slotProps.data.customer.phone }}
          </a>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.mobile" :header="$t('mobile')" filterField="customerMobile" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column field="customer.email" :header="$t('email')"> </Column>

      <Column field="sales.notes" :header="$t('notes')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="sales.phase"
        :header="$t('Phase')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
      >
        <template #body="slotProps">
          <b-badge
            v-if="slotProps.data.sales && slotProps.data.sales.phase"
            :variant="getSalesPhaseColor(slotProps.data.sales.phase)"
          >
            {{ $t(slotProps.data.sales.phase) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('SalesPhase')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getSalesPhaseColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getSalesPhaseColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.salesPhases"
            :options="salesPhases"
            @input="onSalesPhaseFilter"
            optionLabel="label"
            :placeholder="$t('phase')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>
      <Column
        field="sales.callState"
        :header="$t('callState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
      >
        <template #body="slotProps">
          <b-badge
            v-if="slotProps.data.sales && slotProps.data.sales.callState"
            :variant="getCallStateColor(slotProps.data.sales.callState)"
          >
            {{ $t(slotProps.data.sales.callState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('SalesCallState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getCallStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getCallStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="sales.kwhprice" :header="$t('kwhprice')" filterField="kwhprice" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column field="sales.usage" :header="$t('usage')" filterField="usage" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import { FilterMatchMode } from 'primevue/api';
import { getYesNoStateColor, getSalesPhaseColor, getCallStateColor } from '@/helpers/colors';
import { generateGoogleMapsLink, startTeamsCall } from '@/helpers/integration';

import { yesNo } from '@/helpers/enums';

export default {
  components: {
    DataTable,
    Column,
    InputText,
    MultiSelect,
  },
  props: {
    phase: {
      type: String,
      required: false,
    },
    cardTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    const uniqueStateKey = this.cardTitle ? this.cardTitle.replace(/\s+/g, '') : this.phase;
    return {
      loading: false,
      filterName: 'sales-table-custom-filters',
      defaultSortField: 'number',
      defaultSortOrder: -1,
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        clientProjectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        customerStreet: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerCity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerPhone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        customerMobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        employerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        solarPlantOverallPower: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        itemPlanningFinishedState: { value: null, label: null, matchMode: FilterMatchMode.EQUALS },
        calendarWeek: { value: null, matchMode: FilterMatchMode.EQUALS },
        guaranteeCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        requiredInstallationTimeinHours: { value: null, matchMode: FilterMatchMode.EQUALS },
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
        installationDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      defaultCustomFilters: {
        employers: [],
        clients: [],
        client: null,
        installationTeams: [],
        installationTypes: [],
        salesPhases: [],
      },
      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        sortField: this.defaultSortField,
        sortOrder: this.defaultSortOrder,
        filterName: `sales-table-${uniqueStateKey}-filters`,
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getProjectCount',
      'getEmployers',
      'getClients',
      'getEnumValues',
    ]),
    salesPhases() {
      const salesPhases = this.getEnumValues('SalesPhase').map((salesPhase) => {
        return { value: salesPhase, label: this.$t(salesPhase) };
      });
      return salesPhases;
    },
    projects() {
      return this.getProjects;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
    employers() {
      if (!this.tableState.customFilters.client) return [];

      const employers = this.getEmployers
        .filter((employer) => employer.client.id === this.tableState.customFilters.client.value)
        .map((employer) => {
          return { value: employer.id.toString(), label: employer.name };
        });
      return employers;
    },
    clients() {
      const clients = this.getClients.map((client) => {
        return { value: client.id.toString(), label: client.name };
      });
      return clients;
    },

    installationTeams() {
      const installationTeams = this.getEnumValues('InstallationTeam').map((installationTeam) => {
        return { value: installationTeam, label: this.$t(installationTeam) };
      });
      return installationTeams;
    },
    installationTypes() {
      const installationTypes = this.getEnumValues('InstallationType').map((installationType) => {
        return { value: installationType, label: this.$t(installationType) };
      });
      return installationTypes;
    },
    optionsYesNo() {
      return yesNo().map((option) => {
        return { value: option, label: this.$t(option) };
      });
    },

    /**
     * Disable employer dropdown if no client is selected
     */
    employerDropdownDisabled() {
      return this.tableState.customFilters.client ? false : true;
    },
  },
  methods: {
    ...mapActions([
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployers',
      'fetchClients',
      'fetchEmployersByClient',
      'fetchEnumValues',
    ]),

    async onSalesPhaseFilter(value) {
      this.tableState.customFilters.salesPhases = this.salesPhases.filter((salesPhase) =>
        value.includes(salesPhase)
      );
      localStorage.setItem(this.customFilterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },
    /**
     * Load remote table data
     */
    async loadTableData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      }
      await this.fetchEnumValues('SalesCallState');
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      this.tableState.sortOrder = this.defaultSortOrder;
      this.tableState.sortField = this.defaultSortField;
      this.tableState.filters = this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.customFilters = this.defaultCustomFilters;
      localStorage.removeItem(this.filterName);
      await this.loadTableData();
    },
    async onStateRestore(event) {
      this.setupFilters();

      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.filterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
    },

    /**
     *
     */
    async onEmployerFilter(value) {
      this.tableState.customFilters.employers = this.employers.filter((employer) =>
        value.includes(employer)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },

    /**
     *
     */
    async onClientFilter(value) {
      this.tableState.customFilters.employers = [];
      this.tableState.customFilters.client = value;
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));

      await this.loadTableData();
    },
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log('onCellEditComplete():', event);
      // console.log(data, newValue, field);
      data[field] = newValue;

      this.save(data);
    },

    /**
     *
     */
    async onInstallationTeamFilter(value) {
      this.tableState.customFilters.installationTeams = this.installationTeams.filter(
        (installationTeam) => value.includes(installationTeam)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },

    /**
     *
     */
    async onInstallationTypeFilter(value) {
      this.tableState.customFilters.installationTypes = this.installationTypes.filter(
        (installationType) => value.includes(installationType)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },
    /**
     *
     */
    isColumnSelected(column) {
      return this.selectedColumns.find((col) => column === col.field);
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 15;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    getYesNoStateColor,
    getSalesPhaseColor,
    getCallStateColor,
    yesNo,
    startTeamsCall,
    generateGoogleMapsLink,
  },
  async created() {
    this.setupFilters();
    if (this.phase) {
      this.tableState.customFilters.salesPhases = [this.phase];
    }
  },
  async mounted() {
    this.selectedColumns = this.columns;
    await this.fetchEnumValues('InstallationTeam');
    await this.fetchEnumValues('InstallationType');
    await this.fetchEnumValues('SalesPhase');

    if (this.isClient) {
      await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      this.tableState.customFilters.client = {
        value: this.getCurrentUser.client.id.toString(),
        label: this.getCurrentUser.client.name,
      };
    } else if (this.isAdmin) {
      await this.fetchEmployers();
      await this.fetchClients();
    }
    this.loading = false;
    await this.loadTableData();

    if (this.phase) {
      const phaseFilterValue = this.salesPhases.find((sp) => sp.value === this.phase);
      this.onSalesPhaseFilter([phaseFilterValue]);
      this.tableState.customFilters.salesPhases = [{ value: this.phase }];
      await this.loadTableData();
    }
  },
};
</script>

<style lang="scss">
.p-column-filter-menu-button {
  display: none;
}
.p-column-filter-clear-button {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

.p-calendar .p-datepicker {
  min-width: 100%;
  width: 29em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
</style>
